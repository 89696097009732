import Account, { AccountType } from '@/store/models/Account'
import { DispatchingCredit } from '@/store/models/DispatchingCredit'
import Vue from 'vue'
import { mapGetters } from 'vuex'

interface ComponentData {
  totalBeneficiary: number
  totalBeneficiaryCreditAmount: number
}
export default Vue.extend({
  data(): ComponentData {
    return {
      totalBeneficiary: 0,
      totalBeneficiaryCreditAmount: 0
    }
  },
  /* watch: {
    soldeDecaissementMoov: {
      immediate: true,
      handler: function(val) {
        console.log(val)
      }
    }
  }, */
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    ...mapGetters('auth', ['user']),
    accounts() {
      return Account.query()
        .where((account: Account) => {
          return (
            account.owner == this.user.organizationId &&
            account.productId == this.getCurrentProduct.id
          )
        })
        .get()
    },
    soldeDecaissementMoov(): number {
      const moovAccount = this.accounts.find((value: any) => {
        return value.type == AccountType.DISBURSEMENT && value.otm == 'moov'
      })
      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeDecaissementMtn(): number {
      const mtnAccount = this.accounts.find((value: any) => {
        return value.type == AccountType.DISBURSEMENT && value.otm == 'mtn'
      })
      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeDecaissement(): number {
      return this.soldeDecaissementMtn + this.soldeDecaissementMoov
    },

    soldeRembourssementMoov(): number {
      const moovAccount = this.accounts.find((value: any) => {
        return value.type == AccountType.REFUND && value.otm == 'moov'
      })
      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeRembourssementMtn(): number {
      const mtnAccount = this.accounts.find((value: any) => {
        return value.type == AccountType.REFUND && value.otm == 'mtn'
      })

      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeRembourssement(): number {
      return this.soldeRembourssementMoov + this.soldeRembourssementMtn
    },

    soldeFraisMtn(): number {
      const mtnAccount = this.accounts.find(value => {
        return value.type == AccountType.FEES && value.otm == 'mtn'
      })

      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeFraisMoov(): number {
      const moovAccount = this.accounts.find(value => {
        return value.type == AccountType.FEES && value.otm == 'moov'
      })

      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeFrais(): number {
      return this.soldeFraisMoov + this.soldeFraisMtn
    },

    soldeInterestMtn(): number {
      const mtnAccount = this.accounts.find(value => {
        return value.type == AccountType.INTEREST && value.otm == 'mtn'
      })

      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeInterestMoov(): number {
      const moovAccount = this.accounts.find(value => {
        return value.type == AccountType.INTEREST && value.otm == 'moov'
      })

      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeInterest(): number {
      return this.soldeInterestMoov + this.soldeInterestMtn
    }
  },

  methods: {
    getTotalBeneficiary(organizationId: string | null) {
      DispatchingCredit.api()
        .totalBeneficiaryAmount(this.getCurrentProduct.id, organizationId)
        .then((value: any) => {
          ;(this.totalBeneficiaryCreditAmount =
            value.response.data.totalAmount),
            (this.totalBeneficiary = value.response.data.nbBeneficiaries)
        })
    }
  },

  created(this: any) {
    Account.api().fetchAll()
    this.getTotalBeneficiary()
  }
})
