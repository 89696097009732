






























import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    title: String,
    value: [String, Number],
    icon: String,
    subtitle: {
      type: String,
      default: 'Solde total'
    },
    subtitleColor: {
      type: String,
      default: null
    },
    currency: Boolean
  }
})
