














































































import Vue, { VueConstructor } from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import CreditLineProvision from '../../../../../store/models/CreditLineProvision'
import Organization from '../../../../../store/models/Organization'
import AccountMixin from '../../../../../mixins/accountMixin'

interface Disbursment {
  transactionNumber: number
  sfd: string
  creditLine: string
  initiatorName: string
  dateTime: string
  amount: number
  mobileOperator: string
}

interface ComponentData {
  fetching: boolean
  refreshTimer: number | null
  states: Record<string, object>
  columns: Record<string, string | boolean>[]
}

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    BalanceCard
  },

  data(): ComponentData {
    return {
      fetching: false,
      refreshTimer: null,
      states: {
        created: { label: 'Nouveau', color: 'blue-600', bg: 'blue-200' },
        pending: { label: 'En attente', color: 'yellow-600', bg: 'yellow-200' },
        successeed: { label: 'Succès', color: 'green-100', bg: 'green-300' },
        failed: { label: 'Échec', color: 'red-100', bg: 'red-300' }
      },
      columns: [
        // {
        //   label: 'N° Transaction',
        //   field: '_iteration',
        //   searchable: true
        // },
        {
          label: 'SFD',
          field: 'organization.name',
          searchable: true
        },
        {
          label: 'Ligne de crédit',
          field: 'creditLine.name',
          searchable: true
        },
        {
          label: 'Nom Initiateur',
          field: 'createdBy.fullname'
        },
        {
          label: 'Date/Heure',
          field: 'createdAt'
        },
        {
          label: 'Montant',
          field: 'amount',
          searchable: true
        },
        {
          label: 'Opérateur Mobile',
          field: 'otm',
          align: 'center',
          searchable: true
        },
        {
          label: 'Status',
          field: 'state',
          align: 'center'
        },
        {
          label: 'Actions',
          field: '_actions',
          align: 'center'
        }
      ]
    }
  },

  mixins: [AccountMixin],

  computed: {
    organization(): Organization {
      return Organization.loggedIn()
    },

    items() {
      return CreditLineProvision.query()
        .with(['createdBy', 'creditLine', 'organization', 'transaction'])
        .where('state', (state: string) => state !== 'canceled')
        .get()
    },

    mtnAmount(this: any) {
      return this.items
        .filter((item: any) => item.otm === 'mtn')
        .reduce((total: number, item: any) => total + item.amount, 0)
    },

    moovAmount(this: any) {
      return this.items
        .filter((item: any) => item.otm === 'moov')
        .reduce((total: number, item: any) => total + item.amount, 0)
    },

    totalAmount(this: any) {
      return this.mtnAmount + this.moovAmount
    },
    moovBalance(this: any): number {
      return this.soldeRembourssementMoov
    },
    mtnBalance(this: any): number {
      return this.soldeRembourssementMtn
    },
    totalBalance(this: any): number {
      return this.moovBalance + this.mtnBalance
    }
  },

  created() {
    this.fetching = true
    CreditLineProvision.api()
      .fetch({
        'state!': 'canceled',
        include: ['organization', 'creditLine', 'transaction', 'createdBy']
      })
      .then(() => {
        this.fetching = false
      })
      .catch((error: any) => {
        this.fetching = false
        console.log(error)
      })
  },

  mounted() {
    if (!this.refreshTimer) {
      // TODO: maybe websocket ?
      this.refreshTimer = setInterval(() => {
        CreditLineProvision.api().fetch({
          include: ['organization', 'creditLine', 'transaction', 'createdBy']
        })
      }, 10000)
    }
  },

  beforeDestroy() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  },

  methods: {
    onSeeDetails(id: string) {
      this.$router.push({
        name: 'disbursments.details',
        params: { disbursmentId: id }
      })
    }
  }
})
